import React, { useEffect, useState } from "react";
import CurvedArrow from "react-curved-arrow";

export default function Signup() {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => setCanRender(true));

  return (
    <div>
      {canRender ? <div>
          <div className="lg:block hidden">
            <CurvedArrow fromSelector="#IntroArrowStartLg" toSelector="#IntroArrowEndLg" middleX={-300} middleY={-20} fromOffsetX={-10} toOffsetX={-25} dynamicUpdate={true} color="#c2007cbd" width={6} zIndex={100}/>
          </div>
          <div className="lg:hidden">
            <CurvedArrow fromSelector="#IntroArrowStartLg" toSelector="#IntroArrowEndLg" middleX={-100} middleY={20} fromOffsetX={-10} toOffsetX={-25} dynamicUpdate={true} color="#c2007cbd" width={6} zIndex={100}/>
          </div>
        </div> : null }
        <div className="text-center">
            
            {/* <span id="IntroArrowStartSm" className=""/> */}
            <p className="font-handwritten text-4xl text-indigo opacity-75">
              <span id="IntroArrowStartLg" className=""/>
              See a Subscribe Sense confirmation flow in action!
            </p>
        </div>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 my-12">
          <div className="relative rounded-2xl px-6 py-10 bg-indigo-700 overflow-hidden shadow-xl sm:px-12 sm:py-12">
            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-indigo-500 text-opacity-40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-indigo-600 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="mx-auto max-w-2xl text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  See how Subscribe Sense prevents interested leads from getting lost in the shuffle.
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-white">
                  Sign up for emails from us and get a demonstration of our friction-reducing tools in the process.
                </p>
              </div>
              <form action={process.env.GATSBY_CK_FORM_SUBMIT_URL}
                method="POST" className="mt-8 space-y-6"
                data-sv-form="2898628" data-uid="c72dcc4e9a" data-version="5" data-format="inline"
              >
                <div className="max-w-xl mx-auto">
                  <label htmlFor="cta-email" className="sr-only">
                    Email address
                  </label>
                  <span id="IntroArrowEndLg" />
                  <input
                    id="cta-email"
                    name="email_address"
                    type="email"
                    className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="max-w-xl mx-auto">
                  <label htmlFor="esp" className="sr-only">Email Marketing Provider</label>
                  <select name="fields[esp]" id="esp" className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300" required>
                    <option value="">Your Email Marketing Provider</option>
                    <option value="convertkit">ConvertKit</option>
                    <option value="mailchimp">MailChimp</option>
                    <option value="sendinblue">Sendinblue</option>
                    <option value="drip">Drip</option>
                    <option value="hubspot">Hubspot</option>
                    <option value="mailerlite">MailerLite</option> 
                    <option value="aweber">AWeber</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="max-w-xl mx-auto">
                  <button
                    type="submit"
                    className="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-400 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  )
}
